import {Type} from "class-transformer";
import {Movement} from "src/models/Movement";

export class MovementCollection {
  total: number

  @Type(() => Movement)
  movements: Movement[]

  get totalClp(): number {
    return this.movements.reduce((total, {amount}) => total + amount, 0)
  }

  constructor() {
    this.total = 0
    this.movements = []
  }
}