import React, {useCallback, useEffect, useState} from "react";
import {LambdaApi} from "src/apis/lambda.api";
import {Stats} from "src/models/Stats";
import {Dollars} from "src/pages/main/components/Dollars/Dollars";
import Movements from "src/pages/main/components/Movements/Movements";
import Navbar from "src/pages/main/components/Navbar/Navbar";
import styles from "./Main.module.css"

const Main: React.FC = () => {
  const [stats, setStats] = useState<Stats>(new Stats())

  const getStats = useCallback(() => {
    LambdaApi.getStats().then(setStats)
  }, [])

  useEffect(() => {
    getStats()

    const timer = setInterval(() => getStats(), 1000 * 30)
    return () => {
      clearInterval(timer)
    }
  }, [getStats])

  return (
    <>
      <Navbar/>
      <div className={styles.container}>
        <Dollars toBuy={stats.buy.total} toSell={stats.sell.total}/>
        <Movements stats={stats}/>
      </div>
    </>
  )
}

export default Main