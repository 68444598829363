import React from "react";
import {formatToCurrency} from "src/helpers/number_formatters";
import {Stats} from "src/models/Stats";
import { Table } from "antd";
import styles from "./Movements.module.css"

interface Props {
  stats: Stats
}

const Movements: React.FC<Props> = ({stats}) => {
  const columns = [
    {
      title: 'ID',
      dateIndex: 'hash',
      render: (_, movement) => movement.hash
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(
        'es-CL',
        {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
      )
    },
    {
      title: 'User ID',
      dataIndex: 'idNumber',
    },
    {
      title: 'Compra (CLP)',
      dataIndex: 'amount',
      align: 'right' as any,
      render: (amount, movement) => formatToCurrency(
        movement.side === "buy" ? amount : 0,
        "CLP",
        true
      )
    },
    {
      title: 'Compra (USD)',
      dataIndex: 'amountInUsd',
      align: 'right' as any,
      render: (amountInUsd, movement) => formatToCurrency(
        movement.side === "buy" ? amountInUsd : 0,
        "USD",
        true
      )
    },
    {
      title: 'Venta (CLP)',
      dataIndex: 'amount',
      align: 'right' as any,
      render: (amount, movement) => formatToCurrency(
        movement.side === "sell" ? amount : 0,
        "CLP",
        true
      )
    },
    {
      title: 'Venta (USD)',
      dataIndex: 'amountInUsd',
      align: 'right' as any,
      render: (amountInUsd, movement) => formatToCurrency(
        movement.side === "sell" ? amountInUsd : 0,
        "USD",
        true
      )
    },
    {
      title: 'Precio cambio',
      dataIndex: 'usdToClp',
      align: 'right' as any,
      render: (usdToClp, movement) => formatToCurrency(
        usdToClp,
        "CLP",
        true,
        2
      )
    }
  ]
  
  return <Table
    columns={columns}
    dataSource={[...stats.buy.movements, ...stats.sell.movements]}
    pagination={false}
    rowKey={({hash}) => hash}
    summary={() => {
      return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={1} className={styles.boldFont}>Total</Table.Summary.Cell>
            <Table.Summary.Cell index={2} className={styles.boldFont}>-</Table.Summary.Cell>
            <Table.Summary.Cell index={3} className={styles.boldFont}>-</Table.Summary.Cell>
            <Table.Summary.Cell index={4} className={styles.boldFont} align="right">
              {formatToCurrency(stats.buy.totalClp, "CLP", true)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} className={styles.boldFont} align="right">
              {formatToCurrency(stats.buy.total, "USD", true)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} className={styles.boldFont} align="right">
              {formatToCurrency(stats.sell.totalClp, "CLP", true)}</Table.Summary.Cell>
            <Table.Summary.Cell index={7} className={styles.boldFont} align="right">
              {formatToCurrency(stats.sell.total, "USD", true)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8} className={styles.boldFont} align="right">
              {formatToCurrency(
                stats.weightedAvgUsdToClp,
                "CLP",
                true,
                2
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>
      )
    }}
  />
}

export default Movements