import {Card, Typography} from "antd";
import React from "react";
import {formatToCurrency} from "src/helpers/number_formatters";

interface Props {
  toBuy: number
  toSell: number
}

export const Dollars: React.FC<Props> = ({toBuy, toSell}) => {
  return (
    <Card bodyStyle={{margin: 10, display: "flex", flexDirection: "column", alignItems: "center"}} style={{width: "40%", alignSelf: "center"}}>
      <Typography.Title style={{fontWeight: 200, margin: 0, marginBottom: 25}}>
        Compras / Ventas
      </Typography.Title>
      <Typography.Title level={3} style={{fontWeight: 200, margin: 0}}>
        Monto Neto
      </Typography.Title>
      <Typography.Title style={{fontWeight: 500, margin: 0}}>
        {formatToCurrency(toBuy - toSell, "USD")}
      </Typography.Title>
    </Card>
  )
}