import React from 'react';
import './App.css';
import {useAuth} from "./auth/auth.context";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";


const App: React.FC = () => {
  const {isAuthenticated} = useAuth()

  if (!isAuthenticated()) return <Login/>

  return <Main/>
}

export default App;
