type CurrencyName = "USD" | "CLP"

const currenciesConfiguration = {
  "USD": {
    locale: "en-US",
    decimals: 2
  },
  "CLP": {
    locale: "es-CL",
    decimals: 0
  }
}

export const formatToCurrency = (value: number, currency: CurrencyName, displaySymbol = false, decimals?: number) => {
  const config = currenciesConfiguration[currency]
  const formatter = new Intl.NumberFormat(config.locale, {
    style: "currency",
    currency,
    currencyDisplay: displaySymbol ? "symbol" : "code",
    minimumFractionDigits: decimals ?? config.decimals,
    maximumFractionDigits: decimals ?? config.decimals
  })
  return formatter.format(value)
}
