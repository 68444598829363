import React from "react";
import {useAuth} from "src/auth/auth.context";
import styles from "./Navbar.module.css"
import {Button} from "antd";

const Navbar: React.FC = () => {
  const {getEmail, logout} = useAuth()

  return (
    <div className={styles.container}>
      <div>
        <img alt="logo" src="/assets/logo-black.svg"/>
      </div>
      <div>
        {getEmail()} <Button className={styles.logoutBtn} onClick={logout} type="link">Salir</Button>
      </div>
    </div>
  )
}

export default Navbar