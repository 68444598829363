import React, {useCallback, useState} from "react";
import {AuthUtils} from "src/auth/utils";
import {LambdaApi} from "../apis/lambda.api";

interface AuthInterface {
  getEmail: () => string
  isAuthenticated: () => boolean
  login: (email: string, password: string) => Promise<boolean>
  logout: () => void
}

const AuthContext = React.createContext<AuthInterface | undefined>(undefined)

export const AuthProvider: React.FC = ({ children }) => {
  const [, update] = useState<Object>()
  const forceUpdate = useCallback(() => update({}), [])

  const isAuthenticated = () => AuthUtils.isAuthenticated()

  const getEmail = () => AuthUtils.getEmail()

  const login = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await LambdaApi.login(email, password)
      setCredentials(response.accessToken, response.refreshToken)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const setCredentials = (accessToken: string, refreshToken: string) => {
    AuthUtils.setCredentials(accessToken, refreshToken)
    forceUpdate()
  }

  const logout = () => {
    AuthUtils.logout()
    forceUpdate()
  }

  return (
    <AuthContext.Provider value={{getEmail, isAuthenticated, login, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext) as AuthInterface