import {Type} from "class-transformer";
import {MovementCollection} from "src/models/MovementCollection";

export class Stats {
  @Type(() => MovementCollection)
  buy: MovementCollection

  @Type(() => MovementCollection)
  sell: MovementCollection

  constructor() {
    this.buy = new MovementCollection()
    this.sell = new MovementCollection()
  }

  get weightedAvgUsdToClp(): number {
    const total = this.buy.total + this.sell.total
    const movements = [...this.buy.movements, ...this.sell.movements]

    return movements.reduce((acc, {usdToClp, amountInUsd}) => {
      return acc + usdToClp * amountInUsd / total
    }, 0)
  }
}