import {plainToInstance} from "class-transformer";
import {Stats} from "src/models/Stats";
import axios from "./axios"

export class LambdaApi {
  static login = async (email: string, password: string) => {
    const response = await axios.post("/login", {email, password})
    return response.data
  }

  static refreshToken = async (email: string, refreshToken: string) => {
    const response = await axios.post("/login", {email, refreshToken})
    return response.data
  }

  static getStats = async () => {
    const response = await axios.get("/stats")
    return plainToInstance(Stats, response.data.stats)
  }
}