import axios from "axios";
import {ACCESS_TOKEN} from "src/auth/constants";
import {AuthUtils} from "src/auth/utils";

const instance = axios.create({
  baseURL: "https://fpeoaikruh.execute-api.us-east-2.amazonaws.com"
})

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  if (config.url !== "/login") {
    config.headers!["Authorization"] = `Bearer ${accessToken}`
  }
  return config
}, (error) => Promise.reject(error))

instance.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== "/login") {
    originalRequest._retry = true
    await AuthUtils.refreshToken()
    return axios(originalRequest)
  } else if (error.response.status === 401 && accessToken) {
    return AuthUtils.logout()
  }
  return Promise.reject(error)
})

export default instance