import jwt from "jwt-decode";
import {LambdaApi} from "src/apis/lambda.api";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "src/auth/constants";

export class AuthUtils {
  static async refreshToken() {
    try {
      const currRefreshToken = localStorage.getItem(REFRESH_TOKEN)
      if (!currRefreshToken) return this.logout()

      const response = await LambdaApi.refreshToken(this.getEmail(), currRefreshToken)
      this.setCredentials(response.accessToken, response.refreshToken)
    } catch (err) {
      console.error(err)
    }
  }

  static setCredentials(accessToken: string, refreshToken: string) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  }

  static getEmail() {
    const token = this.getAccessToken()
    if (token) {
      const decoded: any = jwt(token)
      return decoded.email
    }
    return ""
  }

  static getAccessToken() {
    if (this.isAuthenticated()) return localStorage.getItem(ACCESS_TOKEN)
    return undefined
  }

  static isAuthenticated() {
    return !!localStorage.getItem(ACCESS_TOKEN)
  }

  static logout() {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
  }
}
