import {Button, Card, Form, Input, message} from "antd";
import React, {useState} from "react";
import {useAuth} from "src/auth/auth.context";
import styles from "./Login.module.css"

const Login: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const {login} = useAuth()

    const onFinish = async ({email, password}) => {
        setLoading(true)
        const success = await login(email, password)
        setLoading(false)

        if (success) {
            message.success(`Ingresando con ${email}`)
        } else {
            message.error("Email y/o contraseña incorrectos")
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <img alt="logo" src='/assets/logo-black.svg'/>
                <br/>
                <Card>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            required
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                          label="Contraseña"
                          name="password"
                          required
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                              type="primary"
                              loading={loading}
                              htmlType="submit"
                              block
                            >
                                Entrar
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default Login